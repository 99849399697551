@font-face {
  font-family: "dripicons-v2";
  src: url("fonts/dripicons-v2.eot");
  src: url("fonts/dripicons-v2.eot?#iefix") format("embedded-opentype"), url("fonts/dripicons-v2.woff") format("woff"), url("fonts/dripicons-v2.ttf") format("truetype"), url("fonts/dripicons-v2.svg#dripicons-v2") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="dripicons-"]:before,
[class*=" dripicons-"]:before {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.dripicons-alarm:before {
  content: "\61"; }

.dripicons-align-center:before {
  content: "\62"; }

.dripicons-align-justify:before {
  content: "\63"; }

.dripicons-align-left:before {
  content: "\64"; }

.dripicons-align-right:before {
  content: "\65"; }

.dripicons-anchor:before {
  content: "\66"; }

.dripicons-archive:before {
  content: "\67"; }

.dripicons-arrow-down:before {
  content: "\68"; }

.dripicons-arrow-left:before {
  content: "\69"; }

.dripicons-arrow-right:before {
  content: "\6a"; }

.dripicons-arrow-thin-down:before {
  content: "\6b"; }

.dripicons-arrow-thin-left:before {
  content: "\6c"; }

.dripicons-arrow-thin-right:before {
  content: "\6d"; }

.dripicons-arrow-thin-up:before {
  content: "\6e"; }

.dripicons-arrow-up:before {
  content: "\6f"; }

.dripicons-article:before {
  content: "\70"; }

.dripicons-backspace:before {
  content: "\71"; }

.dripicons-basket:before {
  content: "\72"; }

.dripicons-basketball:before {
  content: "\73"; }

.dripicons-battery-empty:before {
  content: "\74"; }

.dripicons-battery-full:before {
  content: "\75"; }

.dripicons-battery-low:before {
  content: "\76"; }

.dripicons-battery-medium:before {
  content: "\77"; }

.dripicons-bell:before {
  content: "\78"; }

.dripicons-blog:before {
  content: "\79"; }

.dripicons-bluetooth:before {
  content: "\7a"; }

.dripicons-bold:before {
  content: "\41"; }

.dripicons-bookmark:before {
  content: "\42"; }

.dripicons-bookmarks:before {
  content: "\43"; }

.dripicons-box:before {
  content: "\44"; }

.dripicons-briefcase:before {
  content: "\45"; }

.dripicons-brightness-low:before {
  content: "\46"; }

.dripicons-brightness-max:before {
  content: "\47"; }

.dripicons-brightness-medium:before {
  content: "\48"; }

.dripicons-broadcast:before {
  content: "\49"; }

.dripicons-browser:before {
  content: "\4a"; }

.dripicons-browser-upload:before {
  content: "\4b"; }

.dripicons-brush:before {
  content: "\4c"; }

.dripicons-calendar:before {
  content: "\4d"; }

.dripicons-camcorder:before {
  content: "\4e"; }

.dripicons-camera:before {
  content: "\4f"; }

.dripicons-card:before {
  content: "\50"; }

.dripicons-cart:before {
  content: "\51"; }

.dripicons-checklist:before {
  content: "\52"; }

.dripicons-checkmark:before {
  content: "\53"; }

.dripicons-chevron-down:before {
  content: "\54"; }

.dripicons-chevron-left:before {
  content: "\55"; }

.dripicons-chevron-right:before {
  content: "\56"; }

.dripicons-chevron-up:before {
  content: "\57"; }

.dripicons-clipboard:before {
  content: "\58"; }

.dripicons-clock:before {
  content: "\59"; }

.dripicons-clockwise:before {
  content: "\5a"; }

.dripicons-cloud:before {
  content: "\30"; }

.dripicons-cloud-download:before {
  content: "\31"; }

.dripicons-cloud-upload:before {
  content: "\32"; }

.dripicons-code:before {
  content: "\33"; }

.dripicons-contract:before {
  content: "\34"; }

.dripicons-contract-2:before {
  content: "\35"; }

.dripicons-conversation:before {
  content: "\36"; }

.dripicons-copy:before {
  content: "\37"; }

.dripicons-crop:before {
  content: "\38"; }

.dripicons-cross:before {
  content: "\39"; }

.dripicons-crosshair:before {
  content: "\21"; }

.dripicons-cutlery:before {
  content: "\22"; }

.dripicons-device-desktop:before {
  content: "\23"; }

.dripicons-device-mobile:before {
  content: "\24"; }

.dripicons-device-tablet:before {
  content: "\25"; }

.dripicons-direction:before {
  content: "\26"; }

.dripicons-disc:before {
  content: "\27"; }

.dripicons-document:before {
  content: "\28"; }

.dripicons-document-delete:before {
  content: "\29"; }

.dripicons-document-edit:before {
  content: "\2a"; }

.dripicons-document-new:before {
  content: "\2b"; }

.dripicons-document-remove:before {
  content: "\2c"; }

.dripicons-dot:before {
  content: "\2d"; }

.dripicons-dots-2:before {
  content: "\2e"; }

.dripicons-dots-3:before {
  content: "\2f"; }

.dripicons-download:before {
  content: "\3a"; }

.dripicons-duplicate:before {
  content: "\3b"; }

.dripicons-enter:before {
  content: "\3c"; }

.dripicons-exit:before {
  content: "\3d"; }

.dripicons-expand:before {
  content: "\3e"; }

.dripicons-expand-2:before {
  content: "\3f"; }

.dripicons-experiment:before {
  content: "\40"; }

.dripicons-export:before {
  content: "\5b"; }

.dripicons-feed:before {
  content: "\5d"; }

.dripicons-flag:before {
  content: "\5e"; }

.dripicons-flashlight:before {
  content: "\5f"; }

.dripicons-folder:before {
  content: "\60"; }

.dripicons-folder-open:before {
  content: "\7b"; }

.dripicons-forward:before {
  content: "\7c"; }

.dripicons-gaming:before {
  content: "\7d"; }

.dripicons-gear:before {
  content: "\7e"; }

.dripicons-graduation:before {
  content: "\5c"; }

.dripicons-graph-bar:before {
  content: "\e000"; }

.dripicons-graph-line:before {
  content: "\e001"; }

.dripicons-graph-pie:before {
  content: "\e002"; }

.dripicons-headset:before {
  content: "\e003"; }

.dripicons-heart:before {
  content: "\e004"; }

.dripicons-help:before {
  content: "\e005"; }

.dripicons-home:before {
  content: "\e006"; }

.dripicons-hourglass:before {
  content: "\e007"; }

.dripicons-inbox:before {
  content: "\e008"; }

.dripicons-information:before {
  content: "\e009"; }

.dripicons-italic:before {
  content: "\e00a"; }

.dripicons-jewel:before {
  content: "\e00b"; }

.dripicons-lifting:before {
  content: "\e00c"; }

.dripicons-lightbulb:before {
  content: "\e00d"; }

.dripicons-link:before {
  content: "\e00e"; }

.dripicons-link-broken:before {
  content: "\e00f"; }

.dripicons-list:before {
  content: "\e010"; }

.dripicons-loading:before {
  content: "\e011"; }

.dripicons-location:before {
  content: "\e012"; }

.dripicons-lock:before {
  content: "\e013"; }

.dripicons-lock-open:before {
  content: "\e014"; }

.dripicons-mail:before {
  content: "\e015"; }

.dripicons-map:before {
  content: "\e016"; }

.dripicons-media-loop:before {
  content: "\e017"; }

.dripicons-media-next:before {
  content: "\e018"; }

.dripicons-media-pause:before {
  content: "\e019"; }

.dripicons-media-play:before {
  content: "\e01a"; }

.dripicons-media-previous:before {
  content: "\e01b"; }

.dripicons-media-record:before {
  content: "\e01c"; }

.dripicons-media-shuffle:before {
  content: "\e01d"; }

.dripicons-media-stop:before {
  content: "\e01e"; }

.dripicons-medical:before {
  content: "\e01f"; }

.dripicons-menu:before {
  content: "\e020"; }

.dripicons-message:before {
  content: "\e021"; }

.dripicons-meter:before {
  content: "\e022"; }

.dripicons-microphone:before {
  content: "\e023"; }

.dripicons-minus:before {
  content: "\e024"; }

.dripicons-monitor:before {
  content: "\e025"; }

.dripicons-move:before {
  content: "\e026"; }

.dripicons-music:before {
  content: "\e027"; }

.dripicons-network-1:before {
  content: "\e028"; }

.dripicons-network-2:before {
  content: "\e029"; }

.dripicons-network-3:before {
  content: "\e02a"; }

.dripicons-network-4:before {
  content: "\e02b"; }

.dripicons-network-5:before {
  content: "\e02c"; }

.dripicons-pamphlet:before {
  content: "\e02d"; }

.dripicons-paperclip:before {
  content: "\e02e"; }

.dripicons-pencil:before {
  content: "\e02f"; }

.dripicons-phone:before {
  content: "\e030"; }

.dripicons-photo:before {
  content: "\e031"; }

.dripicons-photo-group:before {
  content: "\e032"; }

.dripicons-pill:before {
  content: "\e033"; }

.dripicons-pin:before {
  content: "\e034"; }

.dripicons-plus:before {
  content: "\e035"; }

.dripicons-power:before {
  content: "\e036"; }

.dripicons-preview:before {
  content: "\e037"; }

.dripicons-print:before {
  content: "\e038"; }

.dripicons-pulse:before {
  content: "\e039"; }

.dripicons-question:before {
  content: "\e03a"; }

.dripicons-reply:before {
  content: "\e03b"; }

.dripicons-reply-all:before {
  content: "\e03c"; }

.dripicons-return:before {
  content: "\e03d"; }

.dripicons-retweet:before {
  content: "\e03e"; }

.dripicons-rocket:before {
  content: "\e03f"; }

.dripicons-scale:before {
  content: "\e040"; }

.dripicons-search:before {
  content: "\e041"; }

.dripicons-shopping-bag:before {
  content: "\e042"; }

.dripicons-skip:before {
  content: "\e043"; }

.dripicons-stack:before {
  content: "\e044"; }

.dripicons-star:before {
  content: "\e045"; }

.dripicons-stopwatch:before {
  content: "\e046"; }

.dripicons-store:before {
  content: "\e047"; }

.dripicons-suitcase:before {
  content: "\e048"; }

.dripicons-swap:before {
  content: "\e049"; }

.dripicons-tag:before {
  content: "\e04a"; }

.dripicons-tag-delete:before {
  content: "\e04b"; }

.dripicons-tags:before {
  content: "\e04c"; }

.dripicons-thumbs-down:before {
  content: "\e04d"; }

.dripicons-thumbs-up:before {
  content: "\e04e"; }

.dripicons-ticket:before {
  content: "\e04f"; }

.dripicons-time-reverse:before {
  content: "\e050"; }

.dripicons-to-do:before {
  content: "\e051"; }

.dripicons-toggles:before {
  content: "\e052"; }

.dripicons-trash:before {
  content: "\e053"; }

.dripicons-trophy:before {
  content: "\e054"; }

.dripicons-upload:before {
  content: "\e055"; }

.dripicons-user:before {
  content: "\e056"; }

.dripicons-user-group:before {
  content: "\e057"; }

.dripicons-user-id:before {
  content: "\e058"; }

.dripicons-vibrate:before {
  content: "\e059"; }

.dripicons-view-apps:before {
  content: "\e05a"; }

.dripicons-view-list:before {
  content: "\e05b"; }

.dripicons-view-list-large:before {
  content: "\e05c"; }

.dripicons-view-thumb:before {
  content: "\e05d"; }

.dripicons-volume-full:before {
  content: "\e05e"; }

.dripicons-volume-low:before {
  content: "\e05f"; }

.dripicons-volume-medium:before {
  content: "\e060"; }

.dripicons-volume-off:before {
  content: "\e061"; }

.dripicons-wallet:before {
  content: "\e062"; }

.dripicons-warning:before {
  content: "\e063"; }

.dripicons-web:before {
  content: "\e064"; }

.dripicons-weight:before {
  content: "\e065"; }

.dripicons-wifi:before {
  content: "\e066"; }

.dripicons-wrong:before {
  content: "\e067"; }

.dripicons-zoom-in:before {
  content: "\e068"; }

.dripicons-zoom-out:before {
  content: "\e069"; }
